import {
  Button,
  Card,
  Column,
  Icon,
  Loader,
  MeatballMenu,
  Row,
  Text,
  Tooltip,
  useDesignTokens,
} from "@gradience/ui";
import {
  createFileRoute,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import { ReactNode, useMemo } from "react";
import PageChrome from "../../../components/page-chrome";
import { useApiDelete, useApiQuery } from "../../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { User } from "@gradience/api-types";
import SchoolInfoModal from "./school-info-modal";
import { useLoggedInUser } from "../../../lib/auth";
import EditUserModal from "../../modals/edit-user";

const modals = ["school-info"] as const;
type Modal = (typeof modals)[number];
type MySchoolSearch = {
  modal?: Modal;
  editingUserId?: string;
};
export const Route = createFileRoute("/$test-slug/my-school/")({
  component: MySchool,
  validateSearch: (search): MySchoolSearch => {
    const validatedSearch: MySchoolSearch = {};

    if (modals.find((v) => v === search.modal)) {
      validatedSearch.modal = search.modal as Modal;
    }

    if (search.editingUserId && typeof search.editingUserId === "string") {
      validatedSearch.editingUserId = search.editingUserId;
    }

    return validatedSearch;
  },
});

const columnHelper = createColumnHelper<User>();

function MySchool(): ReactNode {
  const designTokens = useDesignTokens();
  const school = useApiQuery("/school", {});
  const users = useApiQuery("/users", {});
  const deleteUser = useApiDelete("/users/:id");
  const user = useLoggedInUser();
  const navigate = useNavigate({ from: "/$test-slug/my-school" });

  const columns = useMemo(
    () => [
      columnHelper.accessor("firstName", {
        header: "Name",
        cell: ({ row }) => (
          <Column>
            <Text>
              {row.original.firstName} {row.original.lastName}
            </Text>
            <Text
              style={{
                color: designTokens.colors.text.disabled,
              }}
            >
              {row.original.email}
            </Text>
          </Column>
        ),
      }),
      columnHelper.accessor("roles", {
        id: "roles",
        header: "Access",
        cell: ({ row }) => (
          <Text>
            {row.original.roles.includes("ADMIN") ? "Full" : "Partial"}
          </Text>
        ),
      }),
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <MeatballMenu
            options={[
              {
                label: "Edit",
                value: "edit",
                icon: "pencil-01",
              },
              ...(row.original.id !== user.data?.id
                ? [
                    {
                      label: "Delete",
                      value: "delete",
                      icon: "trash-04",
                    } as const,
                  ]
                : []),
            ]}
            onSelect={async (option) => {
              switch (option) {
                case "delete":
                  if (
                    window.confirm("Are you sure you want to delete this user?")
                  ) {
                    await deleteUser.mutateAsync({
                      body: {},
                      params: { id: row.original.id },
                    });
                    users.refetch();
                  }
                  break;
                case "edit":
                  navigate({
                    search: (prev) => ({
                      ...prev,
                      editingUserId: row.original.id,
                    }),
                  });
                  break;
              }
            }}
          />
        ),
      }),
    ],
    [
      deleteUser,
      designTokens.colors.text.disabled,
      navigate,
      user.data?.id,
      users,
    ]
  );
  const table = useReactTable({
    columns,
    data: users.data?.data || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { editingUserId } = useSearch({ from: "/$test-slug/my-school/" });

  return (
    <PageChrome loading={school.isLoading}>
      <EditUserModal
        isOpen={editingUserId !== undefined}
        userId={editingUserId ?? null}
        onClose={() =>
          navigate({
            search: (prev) => ({ ...prev, editingUserId: undefined }),
          })
        }
        loading={users.isLoading}
      />
      <SchoolInfoModal />
      <Column
        style={{
          gap: 40,
          flexGrow: 1,
        }}
      >
        <Row
          gap={16}
          style={{
            alignItems: "flex-start",
          }}
        >
          <Column gap={4} style={{ flex: 1 }}>
            <Text textStyle="32px - Bold">My School</Text>
            <Text textStyle="14px - Semibold">{school.data?.name}</Text>
          </Column>
          <Button
            text="Update School Info"
            size="small"
            linkProps={{
              search: {
                modal: "school-info",
              },
            }}
          />
        </Row>
        <Card
          style={{
            padding: 0,
          }}
        >
          <Column>
            <Row
              style={{
                padding: 24,
                gap: 12,
                alignItems: "center",
              }}
            >
              <Text textStyle="20px - Bold" style={{ flex: 1 }}>
                Faculty
              </Text>

              <Button
                text="Add Faculty"
                size="small"
                leadingIcon="plus-01"
                linkProps={{
                  search: (prev) => ({ ...prev, modal: "create-user" }),
                }}
              />
            </Row>
            {users.isLoading ? (
              <Loader />
            ) : (
              <table
                style={{
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          style={{
                            padding: 16,
                            cursor: header.column.getCanSort()
                              ? "pointer"
                              : undefined,
                          }}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          <Tooltip
                            content={
                              header.id === "roles" ? (
                                <Column
                                  gap={8}
                                  style={{
                                    textAlign: "start",
                                  }}
                                >
                                  <Text textStyle="caption">
                                    There are two levels of access: partial and
                                    full.{" "}
                                  </Text>
                                  <Text textStyle="caption">
                                    Partial access
                                    <br />
                                    • Create & edit groups
                                    <br />• View group-level results
                                  </Text>

                                  <Text textStyle="caption">
                                    Full access
                                    <br />
                                    • Everything in partial access
                                    <br />
                                    • Delete groups
                                    <br />
                                    • Add partial-access users
                                    <br />• View school-wide results
                                  </Text>
                                </Column>
                              ) : undefined
                            }
                          >
                            {header.isPlaceholder ? null : (
                              <Row gap={6} style={{ alignItems: "center" }}>
                                {header.column.getIsSorted() ? (
                                  <Text
                                    textStyle="caption"
                                    style={{
                                      color: designTokens.colors.text.light,
                                    }}
                                  >
                                    {{ asc: "▲", desc: "▼" }[
                                      header.column.getIsSorted() as string
                                    ] ?? null}
                                  </Text>
                                ) : null}

                                <Text
                                  textStyle="subHeading"
                                  style={{
                                    textTransform: "uppercase",
                                    textAlign: "start",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </Text>

                                {header.id === "roles" && (
                                  <Icon name="information-circle-contained" />
                                )}
                              </Row>
                            )}
                          </Tooltip>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            padding: "24px 16px",
                            borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                          }}
                        >
                          <Text textStyle="14px - Medium">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Text>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            )}
          </Column>
        </Card>
      </Column>
    </PageChrome>
  );
}
