var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Text from "./text";
import { useDesignTokens } from "../lib/design-tokens";
import { Column } from "./layout";
var RadioInput = function (_a) {
    var label = _a.label, helpText = _a.helpText, errorText = _a.errorText, required = _a.required, name = _a.name, options = _a.options, value = _a.value, setValue = _a.setValue, disabled = _a.disabled, style = _a.style, inputProps = _a.inputProps;
    var designTokens = useDesignTokens();
    var handleChange = function (e) {
        if (setValue) {
            setValue(JSON.parse(e.target.value));
        }
    };
    return (_jsxs("span", { style: __assign({ display: "flex", flexDirection: "column", gap: 12 }, style), children: [label && (_jsx("label", { children: _jsx(Text, { textStyle: "strong", children: required ? "".concat(label, "*") : label }) })), _jsx(Column, { style: {
                    gap: 8,
                }, children: options.map(function (option) { return (_jsxs("label", { htmlFor: "".concat(name, "-").concat(option.value), style: {
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 8,
                        cursor: disabled ? "not-allowed" : "pointer",
                        opacity: disabled ? 0.6 : 1,
                        position: "relative",
                    }, children: [_jsx("input", __assign({ type: "radio", id: "".concat(name, "-").concat(option.value), name: name, value: JSON.stringify(option.value), checked: value === option.value, onChange: handleChange, disabled: disabled }, inputProps, { style: {
                                appearance: "none",
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                border: "2px solid ".concat(value === option.value
                                    ? designTokens.colors.misc[100]
                                    : designTokens.colors.border.default),
                                outline: "none",
                                cursor: disabled ? "not-allowed" : "pointer",
                                position: "relative",
                            } })), value === option.value && (_jsx(_Fragment, { children: _jsx("span", { style: {
                                    position: "absolute",
                                    width: 12,
                                    height: 12,
                                    borderRadius: "50%",
                                    backgroundColor: designTokens.colors.misc[100],
                                    top: 4,
                                    left: 4,
                                } }) })), _jsx(Text, { textStyle: "body", children: option.label })] }, JSON.stringify(option.value))); }) }), helpText && _jsx(Text, { textStyle: "body", children: helpText }), errorText && (_jsx(Text, { textStyle: "body", style: {
                    color: designTokens.colors.text.brand,
                }, children: errorText }))] }));
};
export default RadioInput;
