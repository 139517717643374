import { useNavigate } from "@tanstack/react-router";
import GroupConfigurationModal from "../../forms/group-configuration-modal";

const CreateGroupModal = ({ isOpen }: { isOpen: boolean }) => {
  // Not correct, but makes ts happy
  const navigate = useNavigate({
    from: "/",
  });

  return (
    <GroupConfigurationModal
      open={isOpen}
      groupId="create"
      onClose={() => {
        navigate({
          search: (prev) => ({ ...prev, modal: undefined }),
        });
      }}
    />
  );
};

export default CreateGroupModal;
