import { Column, Loader, Row, Text, useDesignTokens } from "@gradience/ui";
import Navbar from "../components/navbar";
import { useLatestTest, useSelectedTest } from "../domain/use-latest-test";
import { colorWithAlpha } from "../lib/colors";

function PageChrome({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading?: boolean;
}) {
  const designTokens = useDesignTokens();
  const selectedTest = useSelectedTest();
  const latestTest = useLatestTest();

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        minHeight: "100dvh",
        boxSizing: "border-box",
        backgroundColor: designTokens.colors.surface.Subdued,
      }}
    >
      <Navbar />
      <Column style={{ flex: 1, padding: "40px", gap: 24, overflow: "scroll" }}>
        {loading ? (
          <Column
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Column>
        ) : (
          <>
            {selectedTest.data?.id !== latestTest.data?.id && (
              <OldYearBanner testName={selectedTest.data?.name ?? ""} />
            )}
            {children}
          </>
        )}
      </Column>
    </div>
  );
}

export default PageChrome;

const OldYearBanner = ({ testName }: { testName: string }) => {
  const designTokens = useDesignTokens();
  const latestTest = useLatestTest();

  return (
    <Row
      style={{
        padding: "16px 24px",
        marginTop: "-24px",
        borderRadius: 16,
        border: `1px solid ${colorWithAlpha(designTokens.colors.misc.Brand, 0.16)}`,
        backgroundColor: "#F9EEEE",
        position: "sticky",
        top: -24,
      }}
    >
      <Text textStyle="16px - Semibold" style={{ flex: 1 }}>
        You’re currently viewing {testName}
      </Text>
      <Text
        to={`/${latestTest.data?.yearIdentifier}/home`}
        textStyle="16px - Semibold"
        style={{
          color: designTokens.colors.misc.Brand,
        }}
      >
        View {latestTest.data?.name}
      </Text>
    </Row>
  );
};
