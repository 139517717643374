const displayPaymentMethod = ({
  credit,
  paymentMethod,
}: {
  credit: number | undefined;
  paymentMethod:
    | {
        last4: string | undefined;
        id: string;
      }
    | undefined;
}) => {
  if (credit) {
    return "credit balance";
  }

  if (paymentMethod) {
    return `card ending in ${paymentMethod.last4}`;
  }

  return null;
};

export default displayPaymentMethod;
