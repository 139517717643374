import { Column, CompactModal, Divider, Modal, Text } from "@gradience/ui";
import { useNavigate } from "@tanstack/react-router";

const SupportModal = ({ isOpen }: { isOpen: boolean }) => {
  // Not correct, but makes ts happy
  const navigate = useNavigate({
    from: "/",
  });

  return (
    <CompactModal
      open={isOpen}
      close={() => {
        navigate({
          search: (prev) => ({ ...prev, modal: undefined }),
        });
      }}
    >
      <Column gap={32}>
        <Text textStyle="24px - Bold">Support</Text>
        <Column gap={8}>
          <Text textStyle="16px - Semibold">ULE Questions</Text>
          <Text textStyle="14px - Medium">
            For questions about the Universal Latin Exam, send an email to{" "}
            <a href="mailto:ULEinfo@instituteforclassicallanguages.org">
              ULEinfo@instituteforclassicallanguages.org
            </a>
          </Text>
        </Column>
        <Divider />
        <Column gap={8}>
          <Text textStyle="16px - Semibold">Technical Issues</Text>
          <Text textStyle="14px - Medium">
            If you’re experiencing a software bug or technical issue, please
            email <a href="mailto:help@gradience.co">help@gradience.co</a> and
            we’ll get back to you within 48 hours.
          </Text>
        </Column>
      </Column>
    </CompactModal>
  );
};

export default SupportModal;
