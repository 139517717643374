import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
var DesignTokensContext = React.createContext({ themeColor: "#000000" });
var StyledComponentsThemeProvider = function (_a) {
    var children = _a.children;
    var designTokens = useDesignTokens();
    return (_jsx(ThemeContext.Provider, { value: designTokens, children: children }));
};
export var DesignTokensProvider = function (_a) {
    var children = _a.children, themeColor = _a.themeColor;
    return (_jsx(DesignTokensContext.Provider, { value: { themeColor: themeColor }, children: _jsx(StyledComponentsThemeProvider, { children: children }) }));
};
export var getDesignTokens = function (themeColor) {
    var gray = {
        0: "#FFFFFF",
        4: "#F6F6F6",
        8: "#ECECEC",
        10: "#EDEDEF",
        12: "#E3E3E3",
        16: "#D9D9D9",
        35: "#9C9BA9",
        48: "#8E8E8E",
        56: "#7B7B7B",
        64: "#696969",
        85: "#1D1C3B",
        88: "#303030",
        100: "#141414",
    };
    var misc = {
        8: "#F4EDED",
        "08": "#F9EEEE",
        "04": "#FCF6F7",
        "100": "#771E23",
        Brand: "#C4323A",
        Award: "#FEC035",
    };
    var offWhite = {
        15: "#F6F1E1",
        20: "#F3EBD6",
    };
    function hexToRgb(hex) {
        var r = parseInt(hex.slice(1, 3), 16);
        var g = parseInt(hex.slice(3, 5), 16);
        var b = parseInt(hex.slice(5, 7), 16);
        return { r: r, g: g, b: b };
    }
    function rgbToHex(r, g, b) {
        return "#".concat(r.toString(16).padStart(2, "0")).concat(g
            .toString(16)
            .padStart(2, "0")).concat(b.toString(16).padStart(2, "0"));
    }
    var surface = {
        Background: gray[0],
        White: gray[0],
        Subdued: gray[4],
        Mid: gray[8],
        Disabled: gray[12],
        "Dark - Disabled": gray[35],
        "Dark - 3": gray[64],
        "Dark - Subdued": gray[88],
        Dark: gray[100],
        Brand: misc[8],
        "Brand - Light": "#F7EAEA",
        "Brand - Navy": gray[85],
    };
    var border = {
        subdued: gray[12],
        default: gray[16],
        light: gray[10],
        dark: gray[100],
        white: gray[0],
    };
    var text = {
        default: gray[100],
        subdued: gray[88],
        disabled: gray[64],
        light: gray[35],
        inverted: gray[0],
        brand: misc["Brand"],
    };
    var icon = {
        default: gray[56],
        white: gray[0],
        dark: gray[100],
        disabled: gray[48],
        brand: misc["Brand"],
    };
    var colors = {
        gray: gray,
        misc: misc,
        offWhite: offWhite,
        surface: surface,
        border: border,
        text: text,
        icon: icon,
    };
    var typography = {
        "32px - Bold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 32,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "36px",
            letterSpacing: "-0.64px",
        },
        "24px - Bold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "-0.48px",
        },
        "20px - Bold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
            letterSpacing: "-0.4px",
        },
        "18px - Bold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "-0.36px",
        },
        "16px - Semibold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "22px",
            letterSpacing: "-0.32px",
        },
        "14px - Semibold": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
        },
        "14px - Medium": {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
        },
        caption: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px",
            letterSpacing: "-0.24px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        headingXL: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 40,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "44px",
            letterSpacing: "-0.8px",
        },
        headingLarge: {
            color: colors.text.default,
            fontSize: 28,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
            letterSpacing: "-0.56px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingMedium: {
            color: colors.text.default,
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "-0.4px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingSmall: {
            color: colors.text.default,
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
            letterSpacing: "-0.36px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingXS: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "-0.32px",
        },
        subHeading: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "1.2px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        body: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        bodyLarge: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            letterSpacing: "-0.4px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        strong: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        button: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
    };
    var breakpoints = {
        xs: 320,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        "2xl": 1536,
    };
    var devices = {
        xs: "(min-width: ".concat(breakpoints.xs, "px)"),
        sm: "(min-width: ".concat(breakpoints.sm, "px)"),
        md: "(min-width: ".concat(breakpoints.md, "px)"),
        lg: "(min-width: ".concat(breakpoints.lg, "px)"),
        xl: "(min-width: ".concat(breakpoints.xl, "px)"),
        "2xl": "(min-width: ".concat(breakpoints["2xl"], "px)"),
    };
    return {
        colors: colors,
        typography: typography,
        breakpoints: breakpoints,
        devices: devices,
    };
};
export var useDesignTokens = function () {
    var context = useContext(DesignTokensContext);
    if (context === undefined) {
        throw new Error("useDesignTokens must be used within a DesignTokensProvider");
    }
    return getDesignTokens(context.themeColor);
};
