import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
var Loader = function () {
    var designTokens = useDesignTokens();
    return (_jsx("span", { style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexBasis: "100%",
            flexGrow: 1,
        }, children: _jsx("svg", { width: "40", height: "40", viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg", stroke: designTokens.colors.icon.default, children: _jsx("g", { fill: "none", fillRule: "evenodd", children: _jsxs("g", { transform: "translate(2 2)", strokeWidth: "4", children: [_jsx("circle", { strokeOpacity: ".5", cx: "18", cy: "18", r: "18" }), _jsx("path", { d: "M36 18c0-9.94-8.06-18-18-18", children: _jsx("animateTransform", { attributeName: "transform", type: "rotate", from: "0 18 18", to: "360 18 18", dur: "1s", repeatCount: "indefinite" }) })] }) }) }) }));
};
export default Loader;
