export const isDatePast = (
  date: Date | undefined | string
): boolean | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const dateAsDate = typeof date === "string" ? new Date(date) : date;

  return dateAsDate < new Date();
};
