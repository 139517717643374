var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Card from "./card";
import Icon from "./icon";
import { Column, Row } from "./layout";
import Text from "./text";
/**
 * Shows some content in a card that can be expanded and collapsed.
 */
var Accordion = function (_a) {
    var children = _a.children, title = _a.title, style = _a.style;
    var _b = useState(false), isOpen = _b[0], setOpen = _b[1];
    return (_jsxs(Card, { style: __assign({ padding: 0, gap: 0 }, style), children: [_jsxs(Row, { style: {
                    cursor: "pointer",
                    padding: 16,
                }, gap: 8, onClick: function () { return setOpen(!isOpen); }, children: [_jsx(Text, { textStyle: "strong", style: { flex: 1 }, children: title }), _jsx(Icon, { name: isOpen ? "chevron-up" : "chevron-down", onClick: function () { return setOpen(!isOpen); }, noButtonStyle: true })] }), isOpen && (_jsx(Column, { style: {
                    padding: "0 16px",
                    paddingBottom: 16,
                }, children: children }))] }));
};
export default Accordion;
