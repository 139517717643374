import { Manifest } from "@gradience/api-types/src/manifest";
import { useApiQuery } from "./api";
import localManifest from "../manifest.json";

const useManifest = () => {
  const { data } = useApiQuery("/manifest", {});

  return data ?? (localManifest as Manifest);
};

export default useManifest;
