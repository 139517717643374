var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { styled } from "styled-components";
import useLockBodyScroll from "../lib/use-lock-body-scroll";
var Modal = function (_a) {
    var children = _a.children, open = _a.open, close = _a.close, style = _a.style;
    useLockBodyScroll(open);
    // Add an effect to listen for 'Esc' key press
    useEffect(function () {
        var handleKeyDown = function (event) {
            if (event.key === "Escape") {
                close();
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeyDown);
        }
        else {
            window.removeEventListener("keydown", handleKeyDown);
        }
        // Cleanup the event listener when component unmounts or modal closes
        return function () {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, close]);
    return (_jsx("div", { style: {
            display: open ? "flex" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            width: "100%",
            height: "100dvh",
            boxSizing: "border-box",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "scroll",
            cursor: "pointer",
            justifyContent: "center",
        }, onClick: close, children: _jsx("div", { style: {
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: "relative",
            }, children: _jsx(OuterContainer, { onClick: function (event) { return event.stopPropagation(); }, style: style, children: _jsxs(InnerContainer, { children: [_jsx(ModalBackground, { children: children }), _jsx(BottomPadding, {})] }) }) }) }));
};
export default Modal;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 720px;\n  cursor: default;\n  min-width: 100%;\n  display: flex;\n  margin-top: 10vh;\n  align-items: flex-end;\n\n  @media only screen and ", " {\n    pointer-events: none;\n    margin-top: 0;\n    align-items: unset;\n    min-width: 50%;\n  }\n"], ["\n  max-width: 720px;\n  cursor: default;\n  min-width: 100%;\n  display: flex;\n  margin-top: 10vh;\n  align-items: flex-end;\n\n  @media only screen and ", " {\n    pointer-events: none;\n    margin-top: 0;\n    align-items: unset;\n    min-width: 50%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var BottomPadding = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media only screen and ", " {\n    height: 10vh;\n    pointer-events: none;\n  }\n"], ["\n  @media only screen and ", " {\n    height: 10vh;\n    pointer-events: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var InnerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  overflow: scroll;\n  border-radius: 10px 10px 0 0;\n  max-height: 90dvh;\n  height: 100%;\n  width: 100%;\n  flex-basis: 100%;\n  box-sizing: border-box;\n\n  @media only screen and ", " {\n    border-radius: 10px;\n    overflow: unset;\n    height: 1px;\n    min-height: 100%;\n    padding-top: 10vh;\n    padding-bottom: 10vh;\n    align-self: flex-start;\n    max-height: unset;\n  }\n"], ["\n  position: relative;\n  overflow: scroll;\n  border-radius: 10px 10px 0 0;\n  max-height: 90dvh;\n  height: 100%;\n  width: 100%;\n  flex-basis: 100%;\n  box-sizing: border-box;\n\n  @media only screen and ", " {\n    border-radius: 10px;\n    overflow: unset;\n    height: 1px;\n    min-height: 100%;\n    padding-top: 10vh;\n    padding-bottom: 10vh;\n    align-self: flex-start;\n    max-height: unset;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var ModalBackground = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: white;\n  box-shadow:\n    0px 8px 28px 0px rgba(0, 0, 0, 0.16),\n    0px 0px 2px 0px rgba(0, 0, 0, 0.16);\n  padding: 36px;\n  padding-bottom: 0;\n  border-radius: 10px 10px 0 0;\n  min-height: 100%;\n  height: auto;\n  box-sizing: border-box;\n  display: flex;\n  pointer-events: auto;\n\n  @media only screen and ", " {\n    border-radius: 10px;\n    padding: 36px;\n  }\n"], ["\n  background-color: white;\n  box-shadow:\n    0px 8px 28px 0px rgba(0, 0, 0, 0.16),\n    0px 0px 2px 0px rgba(0, 0, 0, 0.16);\n  padding: 36px;\n  padding-bottom: 0;\n  border-radius: 10px 10px 0 0;\n  min-height: 100%;\n  height: auto;\n  box-sizing: border-box;\n  display: flex;\n  pointer-events: auto;\n\n  @media only screen and ", " {\n    border-radius: 10px;\n    padding: 36px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var ModalButtonsRowWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  right: 0;\n\n  @media only screen and ", " {\n    position: relative;\n  }\n"], ["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  right: 0;\n\n  @media only screen and ", " {\n    position: relative;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var ModalButtonsRowInner = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n  border-top: 1px solid var(--border-default, #bfbfbf);\n\n  margin-left: -36px;\n  margin-right: -36px;\n  padding: 8px;\n\n  @media only screen and ", " {\n    flex: 1;\n    align-items: flex-end;\n    margin: 0;\n    padding: 0;\n    border-top: none;\n    background: none;\n    position: relative;\n    & > * {\n      flex-grow: unset;\n    }\n  }\n"], ["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n  border-top: 1px solid var(--border-default, #bfbfbf);\n\n  margin-left: -36px;\n  margin-right: -36px;\n  padding: 8px;\n\n  @media only screen and ", " {\n    flex: 1;\n    align-items: flex-end;\n    margin: 0;\n    padding: 0;\n    border-top: none;\n    background: none;\n    position: relative;\n    & > * {\n      flex-grow: unset;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
export var ModalButtonsRow = function (_a) {
    var children = _a.children, style = _a.style;
    return (
    // Wrapper to make it fill vertical space in modal
    _jsx(ModalButtonsRowWrapper, { style: style, children: _jsx(ModalButtonsRowInner, { children: children }) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
