var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "styled-components";
var LayoutElement = function (_a) {
    var children = _a.children, style = _a.style, gap = _a.gap, grow = _a.grow, direction = _a.direction, props = __rest(_a, ["children", "style", "gap", "grow", "direction"]);
    return (_jsx("div", __assign({}, props, { style: __assign({ display: "flex", flexDirection: direction, gap: gap, flexGrow: grow ? 1 : undefined }, style), children: children })));
};
export var Row = function (props) { return (_jsx(LayoutElement, __assign({ direction: "row", style: __assign({ flexWrap: props.wrap ? "wrap" : undefined }, props.style) }, props))); };
export var Column = function (props) { return (_jsx(LayoutElement, __assign({ direction: "column" }, props))); };
export var VisibleMedium = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n\n  @media only screen and ", " {\n    display: flex;\n  }\n"], ["\n  display: none;\n\n  @media only screen and ", " {\n    display: flex;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
export var InvisibleMedium = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n\n  @media only screen and ", " {\n    display: none;\n  }\n"], ["\n  display: flex;\n\n  @media only screen and ", " {\n    display: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
export var MobileColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and ", " {\n    flex-direction: row;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and ", " {\n    flex-direction: row;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.devices.md;
});
var templateObject_1, templateObject_2, templateObject_3;
