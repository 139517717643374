/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/~__root'
import { Route as VerifyEmailImport } from './routes/~verify-email'
import { Route as SignUpImport } from './routes/~sign-up'
import { Route as ResetPasswordImport } from './routes/~reset-password'
import { Route as RequestPasswordResetImport } from './routes/~request-password-reset'
import { Route as LogInImport } from './routes/~log-in'
import { Route as IndexImport } from './routes/~index'
import { Route as TestSlugGroupsIdImport } from './routes/~$test-slug/~groups/~$id'
import { Route as TestSlugMySchoolIndexImport } from './routes/~$test-slug/~my-school/~index'
import { Route as TestSlugHomeIndexImport } from './routes/~$test-slug/~home/~index'

// Create/Update Routes

const VerifyEmailRoute = VerifyEmailImport.update({
  id: '/verify-email',
  path: '/verify-email',
  getParentRoute: () => rootRoute,
} as any)

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const RequestPasswordResetRoute = RequestPasswordResetImport.update({
  id: '/request-password-reset',
  path: '/request-password-reset',
  getParentRoute: () => rootRoute,
} as any)

const LogInRoute = LogInImport.update({
  id: '/log-in',
  path: '/log-in',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const TestSlugGroupsIdRoute = TestSlugGroupsIdImport.update({
  id: '/$test-slug/groups/$id',
  path: '/$test-slug/groups/$id',
  getParentRoute: () => rootRoute,
} as any)

const TestSlugMySchoolIndexRoute = TestSlugMySchoolIndexImport.update({
  id: '/$test-slug/my-school/',
  path: '/$test-slug/my-school/',
  getParentRoute: () => rootRoute,
} as any)

const TestSlugHomeIndexRoute = TestSlugHomeIndexImport.update({
  id: '/$test-slug/home/',
  path: '/$test-slug/home/',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/log-in': {
      id: '/log-in'
      path: '/log-in'
      fullPath: '/log-in'
      preLoaderRoute: typeof LogInImport
      parentRoute: typeof rootRoute
    }
    '/request-password-reset': {
      id: '/request-password-reset'
      path: '/request-password-reset'
      fullPath: '/request-password-reset'
      preLoaderRoute: typeof RequestPasswordResetImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/verify-email': {
      id: '/verify-email'
      path: '/verify-email'
      fullPath: '/verify-email'
      preLoaderRoute: typeof VerifyEmailImport
      parentRoute: typeof rootRoute
    }
    '/$test-slug/home/': {
      id: '/$test-slug/home/'
      path: '/$test-slug/home'
      fullPath: '/$test-slug/home'
      preLoaderRoute: typeof TestSlugHomeIndexImport
      parentRoute: typeof rootRoute
    }
    '/$test-slug/my-school/': {
      id: '/$test-slug/my-school/'
      path: '/$test-slug/my-school'
      fullPath: '/$test-slug/my-school'
      preLoaderRoute: typeof TestSlugMySchoolIndexImport
      parentRoute: typeof rootRoute
    }
    '/$test-slug/groups/$id': {
      id: '/$test-slug/groups/$id'
      path: '/$test-slug/groups/$id'
      fullPath: '/$test-slug/groups/$id'
      preLoaderRoute: typeof TestSlugGroupsIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/log-in': typeof LogInRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/reset-password': typeof ResetPasswordRoute
  '/sign-up': typeof SignUpRoute
  '/verify-email': typeof VerifyEmailRoute
  '/$test-slug/home': typeof TestSlugHomeIndexRoute
  '/$test-slug/my-school': typeof TestSlugMySchoolIndexRoute
  '/$test-slug/groups/$id': typeof TestSlugGroupsIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/log-in': typeof LogInRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/reset-password': typeof ResetPasswordRoute
  '/sign-up': typeof SignUpRoute
  '/verify-email': typeof VerifyEmailRoute
  '/$test-slug/home': typeof TestSlugHomeIndexRoute
  '/$test-slug/my-school': typeof TestSlugMySchoolIndexRoute
  '/$test-slug/groups/$id': typeof TestSlugGroupsIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/log-in': typeof LogInRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/reset-password': typeof ResetPasswordRoute
  '/sign-up': typeof SignUpRoute
  '/verify-email': typeof VerifyEmailRoute
  '/$test-slug/home/': typeof TestSlugHomeIndexRoute
  '/$test-slug/my-school/': typeof TestSlugMySchoolIndexRoute
  '/$test-slug/groups/$id': typeof TestSlugGroupsIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/log-in'
    | '/request-password-reset'
    | '/reset-password'
    | '/sign-up'
    | '/verify-email'
    | '/$test-slug/home'
    | '/$test-slug/my-school'
    | '/$test-slug/groups/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/log-in'
    | '/request-password-reset'
    | '/reset-password'
    | '/sign-up'
    | '/verify-email'
    | '/$test-slug/home'
    | '/$test-slug/my-school'
    | '/$test-slug/groups/$id'
  id:
    | '__root__'
    | '/'
    | '/log-in'
    | '/request-password-reset'
    | '/reset-password'
    | '/sign-up'
    | '/verify-email'
    | '/$test-slug/home/'
    | '/$test-slug/my-school/'
    | '/$test-slug/groups/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LogInRoute: typeof LogInRoute
  RequestPasswordResetRoute: typeof RequestPasswordResetRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  SignUpRoute: typeof SignUpRoute
  VerifyEmailRoute: typeof VerifyEmailRoute
  TestSlugHomeIndexRoute: typeof TestSlugHomeIndexRoute
  TestSlugMySchoolIndexRoute: typeof TestSlugMySchoolIndexRoute
  TestSlugGroupsIdRoute: typeof TestSlugGroupsIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LogInRoute: LogInRoute,
  RequestPasswordResetRoute: RequestPasswordResetRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  SignUpRoute: SignUpRoute,
  VerifyEmailRoute: VerifyEmailRoute,
  TestSlugHomeIndexRoute: TestSlugHomeIndexRoute,
  TestSlugMySchoolIndexRoute: TestSlugMySchoolIndexRoute,
  TestSlugGroupsIdRoute: TestSlugGroupsIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "~__root.tsx",
      "children": [
        "/",
        "/log-in",
        "/request-password-reset",
        "/reset-password",
        "/sign-up",
        "/verify-email",
        "/$test-slug/home/",
        "/$test-slug/my-school/",
        "/$test-slug/groups/$id"
      ]
    },
    "/": {
      "filePath": "~index.tsx"
    },
    "/log-in": {
      "filePath": "~log-in.tsx"
    },
    "/request-password-reset": {
      "filePath": "~request-password-reset.tsx"
    },
    "/reset-password": {
      "filePath": "~reset-password.tsx"
    },
    "/sign-up": {
      "filePath": "~sign-up.tsx"
    },
    "/verify-email": {
      "filePath": "~verify-email.tsx"
    },
    "/$test-slug/home/": {
      "filePath": "~$test-slug/~home/~index.tsx"
    },
    "/$test-slug/my-school/": {
      "filePath": "~$test-slug/~my-school/~index.tsx"
    },
    "/$test-slug/groups/$id": {
      "filePath": "~$test-slug/~groups/~$id.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
