import { Text, Icon, useDesignTokens, Button } from "@gradience/ui";
import { Link } from "@tanstack/react-router";
import { styled } from "styled-components";
import { ReactNode, useEffect, useState } from "react";
import { useIsMobile } from "../lib/use-window-dimensions";
import useManifest from "../lib/use-manifest";
import { Column, Row } from "@gradience/ui";
import { useLockBodyScroll } from "@gradience/ui";
import { IconName } from "@gradience/ui/dist/components/icon";
import { useIsAdmin, useLogout } from "../lib/auth";
import { useSelectedTest } from "../domain/use-latest-test";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.inverted};
  text-decoration: none;
  display: block;
  padding: 16px;
  border-radius: 16px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  transition: background-color 0.2s;
  min-height: 52px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "transparent" : theme.colors.misc["08"]};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.misc["08"]};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text.brand};
  }
`;

const SideBarLink = ({
  to,
  children,
  icon,
  disabled,
}: {
  to: string;
  children: ReactNode;
  icon: IconName;
  disabled?: boolean;
}) => {
  const designTokens = useDesignTokens();

  return (
    <StyledLink
      disabled={disabled}
      activeProps={{
        className: "active",
      }}
      to={to}
      children={({ isActive }) => {
        return (
          <>
            <Icon
              name={icon}
              color={
                isActive
                  ? designTokens.colors.icon.brand
                  : designTokens.colors.icon.dark
              }
            />
            <Text
              style={{
                flexGrow: 1,
                color: isActive
                  ? designTokens.colors.text.brand
                  : designTokens.colors.text.default,
              }}
            >
              {children}
            </Text>
          </>
        );
      }}
    />
  );
};

function Navbar() {
  const [open, setOpen] = useState(true);
  const route = window.location.pathname;
  const isMobile = useIsMobile();
  const manifest = useManifest();
  const designTokens = useDesignTokens();
  const hasFullAccess = useIsAdmin();

  // Close the navbar when navigating
  useEffect(() => {
    setOpen(false);
  }, [route]);

  useLockBodyScroll(open && isMobile);
  const logout = useLogout();
  const selectedTest = useSelectedTest();

  return !isMobile || open ? (
    <Column
      style={{
        flexGrow: 0,
        flexShrink: 0,
        boxSizing: "border-box",
        margin: 8,
        width: 248,
        border: `1px solid ${designTokens.colors.border.light}`,
        borderRadius: 16,
        backgroundColor: designTokens.colors.surface.Background,
        maxHeight: "calc(100dvh - 16px)",
        position: "sticky",
        top: 8,
      }}
    >
      <Row
        style={{
          padding: "32px 20px",
        }}
      >
        <Link style={{ flex: 1 }} to="/">
          <img
            src={manifest.logoUrl}
            alt={`${manifest.name} Logo`}
            style={{
              maxHeight: 32,
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
        {isMobile && (
          <Icon
            name="close"
            onClick={() => setOpen(false)}
            style={{
              justifySelf: "flex-end",
            }}
          />
        )}
      </Row>
      <Column gap={8} style={{ padding: 12, flex: 1 }}>
        <SideBarLink
          to={`/${selectedTest.data?.yearIdentifier}/home`}
          icon="home-04"
        >
          Home
        </SideBarLink>
        <SideBarLink to="/results" icon="bar-chart-square-03" disabled>
          Results
        </SideBarLink>
        {hasFullAccess && (
          <SideBarLink
            to={`/${selectedTest.data?.yearIdentifier}/my-school`}
            icon="bank"
          >
            My School
          </SideBarLink>
        )}
      </Column>
      <Column
        style={{
          padding: 12,
        }}
      >
        <SideBarLink to={`?modal=support`} icon="help-circle-contained">
          Support
        </SideBarLink>
      </Column>
      <Button
        style={{ margin: 8 }}
        text="Log Out"
        onPress={() => logout.mutate()}
      />
    </Column>
  ) : (
    <Icon
      name="menu"
      onClick={() => setOpen(true)}
      style={{
        position: "absolute",
        top: 32,
        right: 24,
      }}
    />
  );
}

export default Navbar;
