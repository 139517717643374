import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { ExtractParams, ResponseForPath, getApi, queryKeys } from "./api";
import { Routes } from "@gradience/api-types";
import { getLoggedInUser } from "./auth";
import config from "./config";

type DefinedSchool = Exclude<ResponseForPath<"/school", "get">, null>;

/**
 * A hook that returns the school associated with the logged in user. If the
 * user doesn't have a school, it will redirect them to the appropriate page
 * while the data is kept in a loading state.
 */
const useSchool = (
  params?: ExtractParams<Routes["/school"]["get"]>,
  options?: UseQueryOptions<DefinedSchool, unknown>
) => {
  const navigate = useNavigate();

  const school = useQuery<DefinedSchool, unknown>(
    [...queryKeys["/school"], params],
    async () => {
      const school = await getApi("/school", params || {});
      if (school === null) {
        const user = getLoggedInUser();
        if (user?.roles.includes("CUSTOMER_SUPPORT")) {
          navigate({ to: "." });
        } else {
          throw new Error("Non customer support user has no school");
        }
        // A bit hacky, but this keeps the data loading until the redirect
        return new Promise(() => {}) as unknown as Awaited<DefinedSchool>; // This creates a Promise that never resolves
      } else {
        return school;
      }
    },
    {
      ...(config.VITE_OFFLINE_DEVELOPMENT_MODE
        ? { networkMode: options?.networkMode ?? "always" }
        : {}),
      ...options,
    }
  );

  return school;
};

export default useSchool;
