import { Button, Column, Row, Text } from "@gradience/ui";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: () => (
    <Column
      style={{
        minHeight: "100dvh",
        alignItems: "center",
        justifyContent: "center",
        gap: 84,
        textAlign: "center",
      }}
    >
      <img style={{ maxWidth: 188 }} src="/exam-logo.png" alt="logo" />
      <Column
        style={{
          gap: 32,
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 790,
        }}
      >
        <Text
          textStyle="32px - Bold"
          style={{
            fontFamily: "Abhaya Libre",
            fontSize: "5em",
            lineHeight: "1.2em",
            fontWeight: "medium",
          }}
        >
          Welcome to the Universal Latin Exam{" "}
        </Text>
        <Text
          textStyle="20px - Bold"
          style={{ fontWeight: "medium", maxWidth: 428 }}
        >
          Earn awards for your school and gain insights into how your curriculum
          is performing.
        </Text>
        <Row gap={16}>
          <Button
            text="Sign Up"
            to="/sign-up"
            size="medium"
            variant="primary"
          />
          <Button text="Log In" to="/log-in" size="medium" />
        </Row>
      </Column>
    </Column>
  ),
});
