import styled from "styled-components";

const UnstyledButtonWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export default UnstyledButtonWrapper;
