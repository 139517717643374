import { Text } from "@gradience/ui";
import gradience from "../images/gradience.png";
import { styled } from "styled-components";
import useManifest from "../lib/use-manifest";
import { Link } from "@tanstack/react-router";
import config from "../lib/config";

const LOGO_WIDTH = "189px";
const CONTENT_WIDTH = "700px";

const ParentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface.Background};
  max-width: 1216px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 64px;
  padding-bottom: 64px;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  row-gap: 64px;
  column-gap: 86px;

  @media only screen and (min-width: calc(${CONTENT_WIDTH} + ${LOGO_WIDTH} + 86px)) {
    flex-wrap: nowrap;
  }
`;

const PaddedContainer = styled.div`
  gap: 58px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: ${CONTENT_WIDTH};
  padding-top: 16px;
  gap: 32px;
  height: 100%;
  overflow-y: auto;
`;

const PoweredByBox = styled.div`
  display: flex;
  max-width: calc(${CONTENT_WIDTH} + ${LOGO_WIDTH} * 2 + 86px);
  width: 100%;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const HideIfFormNotFits = styled.div`
  @media only screen and (max-width: calc(${CONTENT_WIDTH} + ${LOGO_WIDTH} + 86px)) {
    display: none;
  }
`;

function AuthChrome({ children }: { children: React.ReactNode }) {
  const manifest = useManifest();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100dvh",
        paddingBottom: 64,
        boxSizing: "border-box",
      }}
    >
      <ParentContainer>
        <div
          style={{
            flex: 1,
            flexBasis: LOGO_WIDTH,
            flexShrink: 0,
            maxWidth: LOGO_WIDTH,
            alignSelf: "flex-start",
          }}
        >
          <span
            style={{
              flexBasis: 112,
            }}
          />
          <Link
            style={{
              flex: 1,
              maxWidth: LOGO_WIDTH,
            }}
            to="/$test-slug/home"
            params={{
              "test-slug": config.VITE_TEST_SLUG,
            }}
          >
            <img
              src={manifest.logoUrl}
              alt={`${manifest.name} Logo`}
              style={{
                maxWidth: "100%",
              }}
            />
          </Link>
        </div>
        <PaddedContainer>{children}</PaddedContainer>
        <HideIfFormNotFits
          style={{
            flex: 1,
            flexShrink: 0,
            flexBasis: LOGO_WIDTH,
          }}
        />
      </ParentContainer>
      <PoweredByBox>
        <a
          style={{
            textDecoration: "none",
            flexDirection: "row",
            gap: 8,
            display: "flex",
          }}
          href="mailto:build@gradience.co?subject=I%20have%20a%20a%20software%20development%20project%20I%20would%20like%20to%20discuss"
        >
          <Text
            textStyle="caption"
            style={{
              fontWeight: 500,
            }}
          >
            The ULE is powered by
          </Text>
          <img
            src={gradience}
            alt="Gradience Logo"
            style={{ height: "1rem" }}
          />
        </a>
      </PoweredByBox>
    </div>
  );
}

export default AuthChrome;
