import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
import Icon from "./icon";
import { Column, Row } from "./layout";
import Text from "./text";
var DataPoint = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, icon = _a.icon;
    var designTokens = useDesignTokens();
    return (_jsxs(Row, { style: {
            padding: 12,
            border: "1px solid ".concat(designTokens.colors.border.light),
            borderRadius: 12,
            gap: 16,
            backgroundColor: designTokens.colors.surface.Background,
            alignItems: "center",
        }, children: [_jsx(Icon, { name: icon, color: designTokens.colors.icon.brand, size: 24 }), _jsxs(Column, { children: [_jsx(Text, { textStyle: "18px - Bold", children: title }), _jsx(Text, { textStyle: "caption", style: {
                            color: designTokens.colors.text.disabled,
                        }, children: subtitle })] })] }));
};
export default DataPoint;
