var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "@tanstack/react-router";
import Icon from "./icon";
import { useDesignTokens } from "../lib/design-tokens";
var Button = function (_a) {
    var text = _a.text, _b = _a.variant, variant = _b === void 0 ? "default" : _b, _c = _a.size, size = _c === void 0 ? "default" : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, to = _a.to, _e = _a.type, type = _e === void 0 ? "button" : _e, href = _a.href, target = _a.target, style = _a.style, linkProps = _a.linkProps, onPress = _a.onPress, _f = _a.loading, loading = _f === void 0 ? false : _f, icon = _a.icon, leadingIcon = _a.leadingIcon;
    var designTokens = useDesignTokens();
    var _g = React.useState("default"), state = _g[0], setState = _g[1];
    var Element = linkProps || to ? Link : "button";
    var isDisabled = disabled || Boolean(loading);
    var inner = (_jsxs("span", { style: {
            display: "flex",
            flexDirection: size === "huge" ? "column" : "row",
            gap: 8,
            alignItems: "center",
            justifyContent: "center",
        }, children: [leadingIcon && (_jsx(Icon, { name: leadingIcon, size: "small", color: designTokens.colors.icon.default })), text, icon && (_jsx(Icon, { name: icon, size: "small", color: variant === "primary"
                    ? designTokens.colors.icon.white
                    : designTokens.colors.icon.default }))] }));
    var commonButtonStyles = __assign({ borderRadius: 12, borderWidth: 1, borderStyle: "solid", cursor: "pointer", transition: "all 0.2s ease-in-out", textDecoration: "none", textAlign: "center" }, designTokens.typography.button);
    var buttonVariantStyles = {
        default: {
            borderColor: designTokens.colors.border.dark,
            backgroundColor: "transparent",
            boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.08), 0px -1px 0px 0px rgba(58, 40, 40, 0.1) inset",
        },
        subdued: {
            borderColor: designTokens.colors.border.subdued,
            backgroundColor: designTokens.colors.surface.Subdued,
        },
        primary: {
            borderColor: designTokens.colors.border.dark,
            backgroundColor: designTokens.colors.surface.Dark,
            color: designTokens.colors.text.inverted,
            boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.08), 0px -1px 0px 0px rgba(0, 0, 0, 0.10) inset",
        },
        plain: {},
    };
    var buttonSizeStyles = {
        default: {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 24,
            paddingRight: 24,
        },
        huge: {
            paddingTop: 36,
            paddingBottom: 36,
            paddingLeft: 24,
            paddingRight: 24,
        },
        medium: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
        },
        small: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
        },
        large: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 28,
            paddingRight: 28,
        },
    };
    var buttonStateStyles = {
        default: {
            default: {},
            hover: {
                opacity: 0.75,
            },
            active: {
                opacity: 0.45,
            },
            disabled: {
                backgroundColor: designTokens.colors.surface.Disabled,
                borderWidth: 0,
                boxShadow: "none",
                color: designTokens.colors.text.disabled,
                cursor: "not-allowed",
            },
        },
        subdued: {
            default: {},
            hover: {
                backgroundColor: designTokens.colors.surface.Disabled,
            },
            active: {
                opacity: 0.45,
            },
            disabled: {
                backgroundColor: designTokens.colors.surface["Dark - Disabled"],
                borderWidth: 0,
                boxShadow: "none",
                color: designTokens.colors.text.disabled,
                cursor: "not-allowed",
            },
        },
        primary: {
            default: {},
            hover: {
                opacity: 0.75,
            },
            active: {
                opacity: 0.45,
            },
            disabled: {
                backgroundColor: designTokens.colors.surface["Dark - Disabled"],
                borderColor: designTokens.colors.surface["Dark - Disabled"],
                boxShadow: "none",
                color: designTokens.colors.text.disabled,
                cursor: "not-allowed",
            },
        },
        plain: {
            default: {},
            hover: {
                opacity: 0.75,
            },
            active: {
                opacity: 0.45,
            },
            disabled: {
                boxShadow: "none",
                color: designTokens.colors.text.disabled,
                cursor: "not-allowed",
            },
        },
    };
    if (href) {
        return (_jsx("a", { style: __assign(__assign(__assign(__assign(__assign({}, commonButtonStyles), buttonVariantStyles[variant]), buttonSizeStyles[size]), buttonStateStyles[variant][isDisabled ? "disabled" : state]), style), onMouseEnter: function () { return setState("hover"); }, onMouseLeave: function () { return setState("default"); }, onMouseDown: function () { return setState("active"); }, onMouseUp: function () { return setState("hover"); }, onClick: onPress, href: href, target: target, children: inner }));
    }
    return (_jsx(Element, __assign({ disabled: isDisabled, onMouseEnter: function () { return setState("hover"); }, onMouseLeave: function () { return setState("default"); }, onMouseDown: function () { return setState("active"); }, onMouseUp: function () { return setState("hover"); }, onClick: onPress, style: __assign(__assign(__assign(__assign(__assign({}, commonButtonStyles), buttonVariantStyles[variant]), buttonSizeStyles[size]), buttonStateStyles[variant][isDisabled ? "disabled" : state]), style), to: to, type: type }, linkProps, { children: inner })));
};
export default Button;
