var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState, } from "react";
import Icon from "./icon";
import Text from "./text";
import { useDesignTokens } from "../lib/design-tokens";
var Input = function (props) {
    var label = props.label, helpText = props.helpText, errorText = props.errorText, required = props.required, type = props.type, name = props.name, autocomplete = props.autocomplete, disabled = props.disabled, value = props.value, setValue = props.setValue, placeholder = props.placeholder, style = props.style, inputProps = props.inputProps, options = props.options;
    var _a = useState(false), isPasswordVisible = _a[0], setIsPasswordVisible = _a[1];
    var togglePasswordVisibility = function () {
        setIsPasswordVisible(!isPasswordVisible);
    };
    var designTokens = useDesignTokens();
    var uniqueId = useMemo(function () { return Math.random().toString(36).substring(7); }, []);
    var Inner = type === "select" ? (_jsxs("span", { style: {
            position: "relative",
            display: "flex",
        }, children: [_jsxs("select", __assign({ value: value, disabled: disabled, id: "".concat(name, "-input-").concat(uniqueId), onChange: function (e) { return setValue && setValue(e.target.value); } }, inputProps, { style: __assign(__assign(__assign({ backgroundColor: disabled
                        ? designTokens.colors.surface.Subdued
                        : "transparent", padding: "20px 16px", appearance: "none", gap: "8px", display: "flex", flex: 1, alignSelf: "stretch", borderRadius: 12, border: "1px solid ".concat(disabled
                        ? designTokens.colors.border.subdued
                        : "rgba(20, 20, 20, 0.40)") }, designTokens.typography.body), { color: disabled
                        ? designTokens.colors.text.subdued
                        : designTokens.colors.text.default, lineHeight: "115%" }), ((inputProps === null || inputProps === void 0 ? void 0 : inputProps.style) || {})), children: [type === "select" &&
                        (options === null || options === void 0 ? void 0 : options.map(function (option) { return (_jsx("option", { value: option.value, children: option.label }, option.value)); })), !required && _jsx("option", { value: "", disabled: true, hidden: true })] })), _jsx(Icon, { name: "caret-down", color: designTokens.colors.icon.default, style: {
                    position: "absolute",
                    right: 16,
                    top: 19,
                    cursor: disabled ? "not-allowed" : "pointer",
                    pointerEvents: "none",
                } })] })) : (_jsxs("span", { style: {
            position: "relative",
            display: "flex",
        }, children: [_jsx("input", __assign({ autoComplete: autocomplete, required: required, name: name, value: value, id: "".concat(name, "-input-").concat(uniqueId), placeholder: placeholder, onChange: function (e) { return setValue && setValue(e.target.value); }, type: isPasswordVisible ? "text" : type, disabled: disabled }, inputProps, { style: __assign(__assign(__assign({ backgroundColor: disabled
                        ? designTokens.colors.surface.Subdued
                        : "transparent", padding: "20px 16px", gap: "8px", display: "flex", flex: 1, alignSelf: "stretch", borderRadius: 12, border: disabled
                        ? "1px solid ".concat(designTokens.colors.border.subdued)
                        : "1px solid rgba(20, 20, 20, 0.40)" }, designTokens.typography.body), { lineHeight: "115%", color: disabled
                        ? designTokens.colors.text.subdued
                        : designTokens.colors.text.default }), ((inputProps === null || inputProps === void 0 ? void 0 : inputProps.style) || {})) })), type === "password" && (_jsx(Icon, { name: isPasswordVisible ? "eye-off" : "eye", color: designTokens.colors.icon.default, style: {
                    position: "absolute",
                    right: 16,
                    top: 15,
                    cursor: disabled ? "not-allowed" : "pointer",
                }, onClick: function () { return togglePasswordVisibility(); } }))] }));
    return (_jsxs("span", { style: __assign({ display: "flex", flexDirection: "column", gap: 12 }, style), children: [label && (_jsx("label", { htmlFor: "".concat(name, "-input-").concat(uniqueId), children: _jsx(Text, { textStyle: "strong", children: required ? "".concat(label, "*") : label }) })), Inner, helpText && _jsx(Text, { textStyle: "body", children: helpText }), errorText && (_jsx(Text, { textStyle: "body", style: {
                    color: designTokens.colors.text.brand,
                }, children: errorText }))] }));
};
export default Input;
